<template>
  <div>
    <el-upload class="upload-demo" :action="uploadUrl" :headers="myHeaders" :on-remove="handleRemove"
      :disabled="type===3" :before-remove="beforeRemove" :on-success="handleSuccess" multiple :file-list="fileList">
      <el-button size="small" type="primary" v-if="type!==3">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">服务类型资质、民政局颁发的志愿者证书等资料</div>
      <div slot="tip" class="el-upload__tip">上传格式为.xls/.doc/pdf/png/jpeg/jpg</div>
    </el-upload>
  </div>
</template>
<script>
import config from '@/api/config'
export default {
  name: '',
  components: {},
  props: {
    url: {
      type: String,
      default: '/volunteerManage/uploadAttachment'
    },
    attachmentList: {
      type: Array
    },
    type: {
      type: Number
    }
  },
  data() {
    return {
      fileList: [],
      idList: [],
      myHeaders: {
        Authorization: JSON.parse(localStorage.getItem('cad_userInfo'))?.token
      }
    }
  },
  watch: {
    attachmentList: {
      handler(val) {
        if (val) {
          this.fileList = this.attachmentList.map((item) => {
            return {
              name: `${item.fileName}.${item.fileType}`,
              url: item.fileAddress,
              id: item.id
            }
          })
          this.idList = this.attachmentList.map((item) => item.id)
          this.$emit('getData', this.idList)
        }
      }
    }
  },
  computed: {
    uploadUrl() {
      return config.CIVIL_HOST + this.url
    }
  },
  created() {},
  mounted() {
    // console.log(this.fileList)
  },
  methods: {
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      let id = ''
      if (file.id) {
        id = file.id
      } else {
        id = file.response.data.id
      }
      this.idList.splice(this.idList.indexOf(id), 1)
      this.$emit('getData', this.idList)
    },
    handleSuccess(res, file, fileList) {
      if (res.state === 0) {
        this.idList.push(res.data.id)
        this.$emit('getData', this.idList)
      }
    }
    // handleExceed(files, fileList) {
    //   console.log(123, file, fileList)
    //   this.$message.warning(
    //     `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
    //       files.length + fileList.length
    //     } 个文件`
    //   )
    // },
  }
}
</script>
<style lang="scss" scoped>
</style>